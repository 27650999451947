'use strict';
class WtwInputSlider{
    constructor(element){
        if(element == null) {return;}
        this.element = element;

        this.trackActive = element.querySelector('.wtw-input-slider--track-active');
        this.displayValues = element.classList.contains('wtw-input-slider--values') ? true : false;

        // Start Slider
        this.startSlider = element.querySelector('.wtw-input-slider--range-start [type=range]');
        if(this.startSlider){
            this.startSliderMin = JSON.parse(this.startSlider.getAttribute('min') || '0');
            this.startSliderMax = JSON.parse(this.startSlider.getAttribute('max') || '100');
            this.startSliderStep = JSON.parse(this.startSlider.getAttribute('step') || '1');
            this.startSliderRange = this.startSliderMax - this.startSliderMin;
            this.startSliderValue = JSON.parse(this.startSlider.value || '0');
            this.startSliderOutput = element.querySelector('[name=wtw-input-slider--range-start-value]');

            // Set initial placement
            this.updateActiveTrackStart(this.startSliderValue);
            this.updateStartLabel(this.startSliderValue);

            // When user moves the start slider
            this.startSlider.addEventListener('input' , e => {
                // Update the value
                this.startSliderValue = e.target.valueAsNumber;

                // If we move past the end point, reset
                if(this.startSliderValue >= this.endSliderValue){
                    this.startSliderValue = this.endSliderValue - this.endSliderStep;
                    this.startSlider.value = this.startSliderValue;
                    return;
                }

                this.updateActiveTrackStart(e.target.valueAsNumber);
                this.updateStartLabel(e.target.valueAsNumber);
            });
        }


        // End Slider
        this.endSlider = element.querySelector('.wtw-input-slider--range-end [type=range]');
        this.endSliderMin = JSON.parse(this.endSlider.getAttribute('min') || '0');
        this.endSliderMax = JSON.parse(this.endSlider.getAttribute('max') || '100');
        this.endSliderStep = JSON.parse(this.endSlider.getAttribute('step') || '1');
        this.endSliderRange = this.endSliderMax - this.endSliderMin;
        this.endSliderValue = JSON.parse(this.endSlider.value || '0');
        this.endSliderOutput = element.querySelector('[name=wtw-input-slider--range-end-value]');

        // Set initial placement
        this.updateActiveTrackEnd(this.endSliderValue);
        this.updateEndLabel(this.endSliderValue);


        // When user moves the end slider
        this.endSlider && this.endSlider.addEventListener('input' , e => {
            // Update the value
            this.endSliderValue = e.target.valueAsNumber;

            // If we move past the start point, reset
            if(this.endSliderValue <= this.startSliderValue){
                this.endSliderValue = this.startSliderValue + this.startSliderStep;
                this.endSlider.value = this.endSliderValue;
                return;
            }

            this.updateActiveTrackEnd(e.target.valueAsNumber);
            this.updateEndLabel(e.target.valueAsNumber);
        });
    }

    updateActiveTrackStart(value){
        const percent = (value - this.startSliderMin) / this.startSliderRange;
        this.trackActive.style.setProperty('--wtw-input-slider--range-start', percent);
        this.startSlider.setAttribute('aria-valuenow', value);
    }

    updateStartLabel(value){
        if(this.displayValues){
            this.startSliderOutput.textContent = value;
        }
        else {
            const percent = (value - this.startSliderMin) / this.startSliderRange;
            this.startSliderOutput.textContent = `${(percent * 100).toFixed(0)}%`;
        }
    }

    updateActiveTrackEnd(value){
        const percent = (value - this.endSliderMin) / this.endSliderRange;
        this.trackActive.style.setProperty('--wtw-input-slider--range-end', percent);
        this.endSlider.setAttribute('aria-valuenow', value);
    }

    updateEndLabel(value){
        if(!this.endSliderOutput){return;}

        if(this.displayValues){
            this.endSliderOutput.textContent = value;
        }
        else {
            const percent = (value - this.endSliderMin) / this.endSliderRange;
            this.endSliderOutput.textContent = `${(percent * 100).toFixed(0)}%`;
        }
    }
}


// On page load, initialize all tooltips
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-input-slider').forEach(element => {
        new WtwInputSlider(element);
    });
});