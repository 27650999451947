'use strict';
class WtwFilter{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.filters = [];

        this.panel = element.querySelector('.wtw-filter--panel');
        this.flyout = element.querySelector('.wtw-flyout')?.WtwFlyout;
        this.options = [...this.panel.querySelectorAll('[type=checkbox]')];
        this.results = element.querySelector('.wtw-filter--results > div');

        // When user clicks to show panel
        this.trigger = element.querySelector('[aria-haspopup="true"]');
        this.trigger?.addEventListener('click', e => {
            const isExpanded = JSON.parse(this.trigger.getAttribute('aria-expanded'));
            if(isExpanded){
                this.closePanel();
                return;
            }
            this.openPanel();
        });

        // When user clicks to apply changes
        this.apply = this.panel.querySelector('.wtw-filter--apply');
        this.apply?.addEventListener('click', e => {
            this.closePanel();
        });

        // When user clicks to reset
        this.reset = this.panel.querySelector('.wtw-filter--reset');
        this.reset?.addEventListener('click', e => {
            this.filters = [];
            this.syncCheckboxes();
            this.displaySelectedFilters();
            this.notify();
            this.closePanel();
        });

        // When user clicks on a filter in the results
        this.results.addEventListener('click', e => {
            if(e.target.nodeName != 'BUTTON') { return; }
            let id = e.target.dataset.filter;
            this.removeFilter(id);
            this.syncCheckboxes();
            this.displaySelectedFilters();
            this.notify();
        });


        this.WtwFilter = this;
        return;
    }

    openPanel(){
        this.trigger.setAttribute('aria-expanded', true);
        if(this.flyout){
            this.flyout.open();
            return;
        }
        this.panel.setAttribute('aria-hidden', false);
    }

    closePanel(){
        this.trigger.setAttribute('aria-expanded', false);
        this.syncFilters();
        this.displaySelectedFilters();
        this.notify();
        if(this.flyout){
            this.flyout.close();
            return;
        }
        this.panel.setAttribute('aria-hidden', true);
    }

    // Toggle checkboxes to be in sync with selected filters
    syncCheckboxes(){
        this.options.forEach(o => {
            let checked = this.filters.find(f => f.id == o.value) ? true : false;
            o.checked = checked;
        });
    }

    // Update filters to be inline with selected checkboxes
    syncFilters(){
        this.filters = [];
        // Get selected options
        this.options.forEach(o => {
            if(!o.checked){ return true; }
            this.name = o.closest('.wtw-checkbox').querySelector('.wtw-checkbox--checkbox + span').textContent;
            this.filters.push({id: o.value, name: this.name.trim()});
        });
    }

    displaySelectedFilters(){
        let tags = ``;
        this.filters.forEach(f => {
            tags += `
            <span class="wtw-tag" role="status">
                <span>${f.name}</span>
                <button aria-label="ARIA LABEL, Close" data-filter=${f.id}>
                    <span class="functional-icons" aria-hidden="true">close</span>
                </button>
            </span>`;
        });
        this.results.innerHTML = tags;
    }

    removeFilter(id){
        let idx = this.filters.findIndex(f => f.id == id);
        this.filters.splice(idx,1);
    }

    // Send a notification event that the filters have changed.
    notify(){
        this.element.dispatchEvent(new CustomEvent('wtwfilterchange', { bubbles: true, detail: { "filters" : this.filters } }));
    }
}


// On page load, initialize all tabs
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-filter').forEach(element => {
        new WtwFilter(element);
    });
});