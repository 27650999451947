// Carousel
// https://www.cssscript.com/simple-responsive-carousel-slideshow-with-vanilla-javascript/
// But modified

'use strict';
class WtwCarousel{
    constructor(element){
        if(element == null) {return;}
        this.element = element;
        this.running = false;
        this.container = element.querySelector('.wtw-carousel--contents');
        this.pager = element.querySelector('.wtw-carousel--pages');
        this.items = [...element.querySelectorAll('.wtw-carousel--slide')];
        this.itemsPerPage = 1;
        this.numPages = Math.ceil(this.items.length / this.itemsPerPage);

        // Properties that can be controlled by CSS
        const style = window.getComputedStyle(element);
        this.currentSlideIndex = parseInt(style.getPropertyValue('--wtw-carousel--current-page') || '0');
        this.smallCount = parseInt(style.getPropertyValue('--wtw-carousel--small-item-count') || '1');
        this.mediumCount = parseInt(style.getPropertyValue('--wtw-carousel--medium-item-count') || '1');
        this.largeCount = parseInt(style.getPropertyValue('--wtw-carousel--large-item-count') || '1');
        // Let CSS know how many items there are total
        this.element.style.setProperty('--wtw-carousel--total-items', this.items.length);
        this.indicatorSelector = element.querySelectorAll('.wtw-carousel--indicator');
        this.indicatorText = element.querySelector('.wtw-carousel--text');

        this.breakpoints = {
            'small' : 0,
            'medium' : 768,
            'large' : 992
        }

        // Action arrows
        var prevArrow = element.querySelector('.wtw-carousel--left-control');
        var nextArrow = element.querySelector('.wtw-carousel--right-control');
        prevArrow.addEventListener('click', e => {this.prevSlide();});
        nextArrow.addEventListener('click', e => {this.nextSlide();});


        // Watch for window resize and update items per page
        this.resizeObserver = new ResizeObserver(async (entries) => {
            for (const entry of entries) {
                // Large
                if(entry.contentRect.width >= this.breakpoints.large){
                    this.itemsPerPage = this.largeCount;
                    this.numPages = Math.ceil(this.items.length / this.itemsPerPage);
                    this.buildPager();
                    this.gotoPage(0);
                    return;
                }
                // Medium
                if(entry.contentRect.width >= this.breakpoints.medium){
                    this.itemsPerPage = this.mediumCount;
                    this.numPages = Math.ceil(this.items.length / this.itemsPerPage);
                    this.buildPager();
                    this.gotoPage(0);
                    return;
                }
                // Small
                if(entry.contentRect.width >= this.breakpoints.small){
                    this.itemsPerPage = this.smallCount;
                    this.numPages = Math.ceil(this.items.length / this.itemsPerPage);
                    this.buildPager();
                    this.gotoPage(0);
                    return;
                }
            }
        });
        this.resizeObserver.observe(document.body);

        // When user clicks on a pager dot
        this.pager.addEventListener('click', e => {
            if(e.target.nodeName != 'BUTTON'){
                return;
            }
            this.gotoPage(e.target.value);
        });


        element.WtwCarousel = this;
    }

    buildPagerButton(page, selected){
        return `
            <li role="presentation">
                <button class="wtw-carousel--indicator" value="${page}" aria-selected="${selected}" tabindex="0">
                    <span>Page ${page}</span>
                </button>
            </li>
        `;
    }

    buildPager(){
        // Reset
        this.pager.innerHTML = '';

        // Build Buttons HTML
        for(let i=0; i < this.numPages; i++){
            this.pager.innerHTML += this.buildPagerButton(i, (i == 0));
        }

        // Update mobile text
        this.indicatorText.innerHTML = `<span>1</span> of ${this.numPages}`;
    }


    gotoPage(page){
        if(this.currentSlideIndex != page){
            this.currentSlideIndex = page;
        }

        // Keep within bounds
        if(page > (this.numPages - 1)){
            page = (this.numPages - 1);
        }
        if(page < 0){
            page = 0;
        }

        // Set the CSS property that triggers the transition
        this.element.style.setProperty('--wtw-carousel--current-page', page);


        // Update pager
        this.setCurrentPagerButton();
    }


    setCurrentPagerButton(){
        // Reset selected
        this.pager.querySelectorAll('[aria-selected="true" i]')?.forEach(b => {
            b.setAttribute('aria-selected', false);
        });

        // Set current
        this.pager.querySelectorAll('button')[this.currentSlideIndex]?.setAttribute('aria-selected', true);

        // Update mobile text
        this.indicatorText.innerHTML = `<span>${this.currentSlideIndex + 1}</span> of ${this.numPages}`;
    }


    prevSlide() {
        this.currentSlideIndex--;

        if(this.currentSlideIndex < 0){
            this.currentSlideIndex = 0;
            return;
        }

        this.gotoPage(this.currentSlideIndex);
    }


    nextSlide() {
        this.currentSlideIndex++;

        if(this.currentSlideIndex > (this.numPages - 1)){
            this.currentSlideIndex = (this.numPages - 1);
            return;
        }

        this.gotoPage(this.currentSlideIndex);
    }
}


// On page load, initialize all tooltips
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-carousel').forEach(element => {
        new WtwCarousel(element);
    });
});
