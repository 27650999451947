'use strict';
class WtwNotificationToast{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.closer = element.querySelector('.wtw-notification-toast--close');

        this.closer.addEventListener('click', e => {
            this.close();
        });

        element.WtwNotificationToast = this;
    }

    open(){
        WtwMotion.fadeIn(this.element);
        this.closer.focus();
    }

    close(){
        WtwMotion.fadeOut(this.element);
    }
}


// On page load, initialize all notifications
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-notification-toast').forEach(element => {
        new WtwNotificationToast(element);
    });
});