'use strict';
class WtwSearch{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.menu = element.querySelector('ul');
        if(!this.menu) {return;}

        this.hidden = element.querySelector('[type=hidden]');
        this.textbox = element.querySelector('.wtw-search--wrapper [type=text]');
        this.current_item = null;
        this.selected_item = null;
        this.items = [...element.querySelectorAll('[role="option"]')];


        // Catch keystrokes when the dropdown is focused
        this.textbox.addEventListener('keydown', e => {
            // Arrow Up
            if(e.code == 'ArrowUp'){
                e.preventDefault();
                this.selectPrevious();
                return;
            }

            // Arrow Down
            if(e.code == 'ArrowDown'){
                e.preventDefault();
                this.selectNext();
                return;
            }

            // Enter or Space
            if(e.code == 'Enter' || e.code == 'NumpadEnter' || e.code == 'Space'){
                e.preventDefault();
                this.setSelected(this.current_item);
                this.hideDropdown();
                return;
            }

            // Tab
            if(e.code == 'Tab'){
                if(this.isExpanded()){
                    e.preventDefault();
                }
                this.setSelected(this.current_item);
                this.hideDropdown();
                return;
            }
        });


        // Pressing escape at any time should close the dropdown
        document.addEventListener('keydown', e => {
            // Escape
            if(e.code == 'Escape'){
                e.preventDefault();
                this.hideDropdown();
            }
        });


        // When a user clicks on an LI, set it as current
        this.menu.addEventListener('click', e => {
            const item = e.target.closest('li');
            if(item.getAttribute('aria-disabled') == 'true' ){
                return;
            }
            this.setSelected(item);
            this.hideDropdown();
            e.stopImmediatePropagation();
        });


        // When the dropdown loses focus
        this.textbox.addEventListener('blur', e => {
            this.hideDropdown();
        });


        // Pressing escape at any time should close the dropdown
        document.addEventListener('keydown', e => {
            // Escape
            if(e.code == 'Escape'){
                e.preventDefault();
                this.hideDropdown();
            }
        });


        // When a user clicks on an LI, set it as current
        this.menu.addEventListener('click', e => {
            const item = e.target.closest('li');
            if(item.getAttribute('aria-disabled') == 'true' ){
                return;
            }
            this.setCurrent(item);
            this.hideDropdown();
        });

        element.WtwSearch = this;
    }


    showDropdown(){
        // Disabled?
        if(this.element.classList.contains('wtw-search--disabled')){
            return;
        }

        this.menu.setAttribute('aria-hidden', false);
        this.textbox.setAttribute('aria-expanded', true);
        if(this.current_item){
            this.current_item.focus();
            this.menu.scrollTo(0, this.current_item.offsetTop);
        }
        this.textbox.focus();
    }

    hideDropdown(){
        if(this.textbox.getAttribute('aria-expanded') == 'true'){
            this.textbox.focus();
        }
        this.menu.setAttribute('aria-hidden', true);
        this.textbox.setAttribute('aria-expanded', false);

    }

    setCurrent(item){
        if(!item) {return;}

        this.items.forEach(i => {
            i.classList.remove('wtw-search--list-item-current');
        });
        item.classList.add('wtw-search--list-item-current');
        this.current_item = item;
        this.textbox.setAttribute('aria-activedescendant', item.id);

        // Update values
        this.menu.scrollTo(0, item.offsetTop);
    }

    setSelected(item){
        if(!item) {return;}

        this.items.forEach(i => {
            i.setAttribute('aria-selected', false);
            i.classList.remove('wtw-search--list-item-current');
        });
        item.setAttribute('aria-selected', true);
        this.selected_item = item;
        this.current_item = item;

        // Update values
        this.textbox.innerHTML = this.selected_item.innerHTML;
        this.hidden.value = this.selected_item.dataset.value;
        this.menu.scrollTo(0, item.offsetTop);
        this.textbox.value = this.current_item.textContent.trim();

        this.notify();
    }

    // Send a notification event that the value has changed.
    notify(){
        this.hidden.dispatchEvent(new CustomEvent('change', { bubbles: true, detail: { "value" : this.hidden.value } }));
    }

    isExpanded() {
        return JSON.parse(this.textbox.getAttribute('aria-expanded') || 'false');
    }

    selectPrevious(){
        // If the menu isn't open, open it and quit
        if(!this.isExpanded()){
            return;
        }

        // Get the index of the current item
        const idx = this.items.indexOf(this.current_item) || 0;

        if(idx == 0){
            return;
        }

        // Get a list of previous items
        const more = this.items.slice(0, idx);

        // Filter out disabled items and select the next one
        const prev = more.filter(item => {return JSON.parse(item.getAttribute('aria-disabled') || 'false') != true;})
        if(prev.length > 0){
            this.setCurrent(prev.at(-1));
        }
        return;
    }

    selectNext(){
        // If the menu isn't open, open it and quit
        if(!this.isExpanded()){
            return;
        }

        // Get the index of the current item
        const idx = this.items.indexOf(this.current_item) || 0;

        // Get a list of subsequent items
        const more = this.items.slice(idx+1);

        // Filter out disabled items and select the next one
        const next = more.filter(item => {return JSON.parse(item.getAttribute('aria-disabled') || 'false') != true;})
        if(next.length > 0){
            this.setCurrent(next[0]);
        }
        return;
    }
}


// On page load, initialize all tree-filters
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-search').forEach(element => {
        new WtwSearch(element);
    });
});