'use strict';
class WtwPopover{
    constructor(element){
        if(element == null) {return;}

        this.showing = false;
        this.element = element;
        this.closeHandler = null;
        this.trigger = element.querySelector('[data-popover]');
        this.popover = element.querySelector('.wtw-popover--popover');
        if(!this.popover) {return;}

        this.popover.setAttribute('aria-hidden', true);

        // Show the popover
        ['click'].forEach(event_name => {
            this.trigger.addEventListener(event_name, e => {
                e.preventDefault();
                if(this.showing){
                    this.hidePopover(e);
                }
                else{
                    this.showPopover();
                }
            });
        });

        // Pressing escape at any time should close popover
        document.addEventListener('keydown', e => {
            if(e.code == 'Escape'){
                this.hidePopover();
            }
        });

        element.WtwPopover = this;
    }

    async showPopover(){
        this.trigger.setAttribute('aria-expanded', true);
        this.trigger.setAttribute('aria-describedby', this.trigger.dataset.popover);

        this.popover.setAttribute('aria-hidden', false);
        this.showing = true;

        // Close the popover when user clicks off
        window.setTimeout(() => {
            document.body.addEventListener('click', this.blurPopover, {once: true});
        }, 100);
    }

    blurPopover = (e) => {
        e.preventDefault();
        this.hidePopover();
    };

    hidePopover(){
        this.trigger.setAttribute('aria-expanded', false);
        this.trigger.removeAttribute('aria-describedby');

        this.popover.setAttribute('aria-hidden', true);
        this.showing = false;

        document.body.removeEventListener('click', this.blurPopover);
    }
}


// On page load, initialize all popovers
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-popover').forEach(element => {
        new WtwPopover(element);
    });
});