'use strict';
class WtwAppBar{
    constructor(element){
        if(!element) {return;}
        this.element = element;
        this.isLarge = false;

        this.backdrop = element.querySelector('.wtw-app-bar--backdrop');
        this.panel = element.querySelector('.wtw-app-bar--panel');
        this.hamburger = element.querySelector('#wtw-hamburger');
        this.closer = element.querySelector('#closeMenu');
        this.menuWrapper = element.querySelector('.wtw-app-bar--menus');
        this.mainMenu = element.querySelector('#mainMenu');
        this.items = element.querySelectorAll('[role=menu] li');
        this.currentItem = this.items[0];


        // When user clicks on hamburger
        this.hamburger.addEventListener('click', e => {
            this.openPanel();
        });

        // When user clicks the close button (mobile)
        this.closer.addEventListener('click', e => {
            this.closePanel();
        });

        // After panel animates, remove
        this.panel.addEventListener('transitionend', e => {
            if(this.isLarge) {return;}

            // Ignore if we're opening the panel
            if(this.isPanelOpen()){
                if(e.target.id == 'wtw-navigation'){
                    this.closer.focus();
                }
                return;
            }

            this.hamburger.focus();

            // Hide the backdrop and panel
            this.panel.setAttribute('hidden', 'hidden');
            this.panel.setAttribute('aria-hidden', true);

            this.backdrop.setAttribute('hidden', 'hidden');
            this.backdrop.setAttribute('aria-hidden', true);
        });


        // Remove height from sub menu after animation
        this.mainMenu?.addEventListener('transitionend', e => {
            e.preventDefault();

            // if(e.target.nodeName == 'INPUT' || e.target.classList.contains('wtw-search-navigation--decoration')){
            //     return;
            // }

            const menuitem = e.target;
            const li = menuitem.closest('li');
            menuitem.style.height = null;

            if(this.isExpanded(li)){
                return;
            }

            // If this is large, collapse children
            const children = menuitem.querySelectorAll('[role=menu] li');
            children.forEach(c => {
                this.closeItem(c);
            });

            menuitem.setAttribute('hidden', true);
        });

        // Watch for window resize
        this.resizeObserver = new ResizeObserver(async (entries) => {
            for (const entry of entries) {
                const islarge = entry.contentRect.width >= 992;

                // Was there a change?
                if(islarge == this.isLarge){return;}

                if(islarge){
                    this.isLarge = true;
                    this.openPanel();
                    // this.closeAllItems();
                    this.backdrop.setAttribute('hidden', 'hidden');
                    this.backdrop.setAttribute('aria-hidden', true);

                    // Instantly all items instead of animating
                    this.items.forEach(i => {
                        i.setAttribute('aria-expanded', false);
                        const submenu = i.querySelector('ul');
                        submenu?.setAttribute('aria-hidden', 'true');
                        submenu?.setAttribute('hidden', true);
                    });
                }
                else {
                    this.isLarge = false;
                    // Hide the backdrop and panel
                    this.panel.setAttribute('hidden', 'hidden');
                    this.panel.setAttribute('aria-hidden', true);

                    this.backdrop.setAttribute('hidden', 'hidden');
                    this.backdrop.setAttribute('aria-hidden', true);

                    this.closePanel();
                    this.closeAllItems();
                }
            }
        });
        this.resizeObserver.observe(document.body);


        // When user clicks on an an item
        this.menuWrapper.addEventListener('click', e => {
            const isSearch = e.target.parentElement.classList.contains('wtw-search-navigation--wrapper');
            if(isSearch){
                return;
            }

            // Toggle sub menu ?
            if(e.target.textContent == 'expand_more'){
                this.toggleItem(e.target.closest('li'));
                this.openPanel();
                return;
            }

            const src = e.target.closest('a');
            if(src){
                this.setCurrent(e.target.closest('li'));
                if(!this.isLarge){
                    // Close nav
                    this.closePanel();
                    return;
                }
                this.closePanel();
                this.closeAllItems();
            }
        });


        // When user clicks backdrop
        this.backdrop.addEventListener('click', e => {
            this.closePanel();
            if(this.isLarge){
                this.closeAllItems();
            }
        });

        // Pressing escape at any time should close the menus / panel
        document.addEventListener('keydown', e => {
            // Escape
            if(e.code == 'Escape'){
                if(this.isLarge){
                    this.closeAllItems();
                    this.closePanel();
                    return;
                }
                this.closePanel();
            }
        });



        // When the menu has focus, make sure we have a current element
        this.mainMenu?.addEventListener('focusin', e => {
            this.setFocus(e.target.closest('li'));
        });


        // Watch for keyboard actions
        this.mainMenu?.addEventListener('keydown', e => {
            const isTopLevel = document.activeElement.getAttribute('aria-level') == "1";

            // Arrow Up
            if(e.code == 'ArrowUp'){
                e.preventDefault();
                this.selectPrevious();
            }

            // Arrow Down
            if(e.code == 'ArrowDown'){
                e.preventDefault();
                this.selectNext();
            }

            // Arrow Right
            if(e.code == 'ArrowRight'){
                e.preventDefault();
                this.openItem(this.currentItem);

                if(this.isLarge && isTopLevel){
                    this.openPanel();
                }
            }

            // Arrow Left
            if(e.code == 'ArrowLeft'){
                e.preventDefault();
                this.closeItem(this.currentItem);
            }

            // Space bar
            if(e.code == 'Space' || e.code == 'Enter' || e.code == 'NumpadEnter'){
                e.preventDefault();
                // If the triggering LI has an a tag, fire it
                e.target.querySelector(':scope > div > a')?.click();
            }
        });


        element.WtwAppBar = this;
        return;
    }

    isPanelOpen(){
        return document.body.classList.contains('wtw-navigation-show');
    }

    openPanel(){
        this.panel.removeAttribute('hidden');
        this.panel.setAttribute('aria-hidden', false);

        this.backdrop.removeAttribute('hidden');
        this.backdrop.setAttribute('aria-hidden', false);

        // Add class to animate
        window.setTimeout(e => {
            document.body.classList.add('wtw-navigation-show');
        }, 0);
    }

    closePanel(){
        document.body.classList.remove('wtw-navigation-show');
        // Hidden attributes are reset with the TransitionEnd event
    }

    setFocus(item){
        if(!item) {return;}

        // Did user click on search?
        if(item.querySelector('.wtw-search-navigation') != null){
            return;
        }

        const items = this.menuItems(item);

        items.forEach(i => {
            i.setAttribute('tabindex', '-1');
        });
        item.setAttribute('tabindex', '0');
        this.currentItem = item;
        this.currentItem.focus();
    }

    setCurrent(item){
        if(!item) {return;}

        this.items.forEach(i => {
            i.setAttribute('aria-current', false);
        });
        item.setAttribute('aria-current', true);

        const items = this.menuItems(item);
        items.forEach(i => {
            i.setAttribute('tabindex', '-1');
        });
        item.setAttribute('tabindex', '0');


        this.currentItem = item;
    }

    // Get all items for menu containing passed in item
    menuItems(item) {
        return item.closest('[role=menu]').querySelectorAll('li');
    }

    isExpanded(item) {
        return item && JSON.parse(item.getAttribute('aria-expanded') || 'false');
    }

    selectPrevious(){
        if(!this.currentItem){
            this.setFocus(this.items[0]);
            return;
        }

        let previous = this.currentItem.previousElementSibling;
        if(!previous){
            // Get the parent element
            const parent = this.currentItem.parentElement.closest('li');
            if(parent){
                this.setFocus(parent);
                return;
            }

            return;
        }

        // const items = this.currentItem.closest('[role=menu]').querySelectorAll('li');
        const items = this.menuItems(this.currentItem);

        // If previous doesn't have children, set it as current
        const idx = [...items].indexOf(this.currentItem) - 1;
        const previous_children = [...items].filter((x,i) => {return i <= idx && x.parentElement.getAttribute('aria-hidden') == 'false' && !x.parentElement.closest('[aria-expanded="false"]')});
        if(!this.isExpanded(previous) || previous_children.length == 0){
            this.setFocus(previous);
            return;
        }


        // Get the furthest visible item
        this.setFocus(previous_children.reverse()[0]);
        return;
    }

    selectNext(){
        if(!this.currentItem){
            this.setFocus(this.items[0]);
            return;
        }

        const submenu = this.currentItem.querySelector('UL');
        // const items = this.currentItem.closest('[role=menu]').querySelectorAll('li');
        const items = this.menuItems(this.currentItem);

        // If the current item has children and is expanded, get first child
        if(submenu && this.isExpanded(this.currentItem)){
            const idx = Array.from(items).indexOf(this.currentItem) + 1;
            if(idx < items.length){
                this.setFocus(items[idx]);
            }
            return;
        }

        // Otherwise get the next sibling
        if(this.currentItem.nextElementSibling){
            this.setFocus(this.currentItem.nextElementSibling);
            return;
        }

        // Keep going up looking for an LI with a sibling
        let parent = this.currentItem.closest('ul').closest('li');
        while(parent && !parent.nextElementSibling){
            parent = parent.closest('ul').closest('li');
        }
        if(!parent) {return;}

        if(parent.nextElementSibling){
            this.setFocus(parent.nextElementSibling);
            return;
        }

        return;
    }

    toggleItem(item){
        if(!item){ return; }

        if(this.isExpanded(item)){
            this.closeItem(item);
        }
        else {
            this.openItem(item);
        }
    }

    async openItem(item){
        // If this doesn't have a submenu, don't expand
        if(![...item.childNodes].find(n => n.nodeName == 'UL')){return;}
        if(this.isExpanded(item)) {return;}

        // Close other items
        const isChild = item.closest('[aria-expanded=true]');
        // this.isLarge && !isChild && this.closeAllItems();
        !isChild && this.closeAllItems();

        item.setAttribute('aria-expanded', true);
        const submenu = item.querySelector('ul');

        // Set height to zero
        submenu.style.height = `0px`;
        submenu.removeAttribute('hidden');
        submenu.setAttribute('aria-hidden', 'false');

        setTimeout(() => {
            this.setHeight(submenu);
        }, 0);
    }

    closeItem(item){
        // If this doesn't have a submenu, don't collapse
        if(![...item.childNodes].find(n => n.nodeName == 'UL')){return;}
        if(!this.isExpanded(item)) {return;}

        item.setAttribute('aria-expanded', false);
        const submenu = item.querySelector('ul');
        submenu.setAttribute('aria-hidden', 'true');

        // Set the height of the panel so we can transiton back to 0
        this.setHeight(submenu);

        setTimeout(() => {
            submenu.style.height = `0px`;
        }, 0);
    }

    async closeAllItems(){
        this.items.forEach(i => {
            // Animate closing other items
            this.closeItem(i);
        });
    }

    setHeight(menu) {
        menu.style.height = `${menu.scrollHeight}px`;
    }
}


// On page load, initialize nav
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-app-bar').forEach(element => {
        new WtwAppBar(element);
    });
});