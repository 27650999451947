'use strict';
class WtwDatagrid {
	constructor(element){
		if(!element) {return;}
        this.element = element;

		// Show fade effect when grid overflows horizontally
		this.scroller = element.closest('.wtw-datagrid--scroll');
		this.scroller && this.scroller.addEventListener('scroll', e => {
			var shadow = this.scroller.querySelector('.wtw-datagrid--scroll-shadow');

			var shadow_hidden = false;
			if(this.scroller.scrollLeft + this.scroller.offsetWidth === this.scroller.scrollWidth) {
				if(!shadow_hidden) {
					shadow.style.visibility = 'hidden';
					shadow_hidden = true;
				}
			}
			else {
				shadow.removeAttribute('style');
			}
		});
	}
}


// On page load, initialize
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-datagrid').forEach(element => {
        new WtwDatagrid(element);
    });
});