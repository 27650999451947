'use strict';
class WtwTabs{
    constructor(element){
        if(!element) {return;}

        this.menu = element.querySelector('ul');
        if(!this.menu) {return;}

        this.items = [...this.menu.querySelectorAll('[role="tab"]')];
        this.current_item = this.menu.querySelector('[aria-selected="true"]');


        // Show fade effect when grid overflows horizontally
        this.fader = element.querySelector('.wtw-tabs--fader');
        this.fader_hidden = false;
        this.wrapper = element.querySelector('.wtw-tabs--wrapper');
        this.wrapper.addEventListener('scroll', e => {
            this.updateScrollFade();
        });
        this.updateScrollFade();


        // When the menu has focus, make sure we have a current element
        this.menu.addEventListener('focus', e => {
            if(!this.current_item){
                this.setCurrent(this.items[0]);
                this.openTab(this.items[0]);
                return;
            }
        });


        // Watch for keyboard actions
        this.menu.addEventListener('keydown', e => {

            // Arrow Up
            if(e.code == 'ArrowUp'){
                e.preventDefault();
                this.selectPrevious();
            }

            // Arrow Down
            if(e.code == 'ArrowDown'){
                e.preventDefault();
                this.selectNext();
            }

            // Arrow Right
            if(e.code == 'ArrowRight'){
                e.preventDefault();
                this.selectNext();
            }

            // Arrow Left
            if(e.code == 'ArrowLeft'){
                e.preventDefault();
                this.selectPrevious();
            }

            // Space bar
            if(e.code == 'Enter' || e.code == 'NumpadEnter' || e.code == 'Space'){
                e.preventDefault();
                this.openTab(this.current_item);
            }
        });


        // When a user clicks on an tab, set it as current
        this.menu.addEventListener('click', e => {
            const item = e.target.closest('button');
            this.setCurrent(item);
            this.openTab(item);
            e.preventDefault();
        });


        element.WtwTabs = this;
    }


    updateScrollFade(){
        // const elem = e.target;
        const elem = this.wrapper;
        if(elem.scrollLeft + elem.offsetWidth === elem.scrollWidth){
            if(!this.fader_hidden) {
                this.fader.style.visibility = "hidden";
                this.fader_hidden = true;
            }
        }
        else{
            if(this.fader_hidden) {
                this.fader.style.visibility = "visible";
                this.fader_hidden = false;
            }
        }
    }

    setCurrent(item){
        if(!item) {return;}
        this.current_item = item;
        this.current_item.focus();
    }

    isSelected(item) {
        return item && JSON.parse(item.getAttribute('aria-selected') || 'false');
    }

    selectPrevious(){
        if(!this.current_item){
            this.setCurrent(this.items[0]);
            return;
        }

        // Prev location
        const idx = this.items.indexOf(this.current_item) - 1;

        // Get the next element
        if(idx < 0){
            this.setCurrent(this.items[this.items.length - 1]);
            return;
        }

        this.setCurrent(this.items[idx]);
    }

    selectNext(){
        if(!this.current_item){
            this.setCurrent(this.items[0]);
            return;
        }

        // Next location
        const idx = this.items.indexOf(this.current_item) + 1;

        // Get the next element
        if(idx >= this.items.length){
            this.setCurrent(this.items[0]);
            return;
        }

        this.setCurrent(this.items[idx]);
    }

    openTab(item){
        if(!item){return;}
        if(this.isSelected(item)) {return;}
        this.current_item = item;

        this.items.forEach(i => {
            i.setAttribute('aria-selected', false);
            i.setAttribute('tabindex', '-1');
        });
        item.setAttribute('aria-selected', true);
        item.setAttribute('tabindex', '0');
        this.notify();
    }

    // Send a notification event that the value has changed.
    notify(){
        const panel = this.current_item.getAttribute('aria-controls');
        this.menu.dispatchEvent(new CustomEvent('change', { bubbles: true, detail: { "value" : panel } }));
    }
}


// On page load, initialize all tabs
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-tabs').forEach(element => {
        new WtwTabs(element);
    });
});