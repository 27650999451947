'use strict';
class WtwDropdownNavigation{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.menu = element.querySelector('ul');
        if(!this.menu) {return;}

        this.current_item = null;
        this.items = [...element.querySelectorAll('[role="menuitem"]')];
        this.animateTrigger = JSON.parse(element.querySelector('[data-animate-trigger]') != null || 'false');

        // The trigger is whatever has the dataset attribute
        this.trigger = element.querySelector('[aria-controls]');

        // When user presses the toggle button
        this.trigger.addEventListener('click', e => {
            const state = this.menu.getAttribute('aria-hidden');
            if(state == 'false'){
                this.hideDropdown();
                return;
            }
            this.showDropdown();
        });


        // Catch keystrokes when the dropdown is focused
        this.trigger.addEventListener('keydown', e => {

            // Arrow Up
            if(e.code == 'ArrowUp'){
                e.preventDefault();
                this.selectPrevious();
                return;
            }

            // Arrow Down
            if(e.code == 'ArrowDown'){
                e.preventDefault();
                this.selectNext();
                return;
            }

            // Enter or Space
            if(e.code == 'Enter' || e.code == 'NumpadEnter' || e.code == 'Space'){
                e.preventDefault();
                if(this.isExpanded()){
                    this.notify();
                    this.hideDropdown();
                    return;
                }

                this.showDropdown();
                return;
            }

            // Tab
            if(e.code == 'Tab'){
                if(this.isExpanded()){
                    e.preventDefault();
                }
                this.hideDropdown();
                return;
            }
        });


        // When the dropdown loses focus
        this.trigger.addEventListener('blur', e => {
            if(this.isExpanded()){
                window.setTimeout(async e => {
                    this.hideDropdown();
                }, 100);
            }
        });


        // Pressing escape at any time should close the dropdown
        document.addEventListener('keydown', e => {
            // Escape
            if(e.code == 'Escape'){
                e.preventDefault();
                this.hideDropdown();
            }
        });


        // When a user clicks on an LI, set it as current
        this.menu.addEventListener('click', e => {
            const item = e.target.closest('li');
            if(!item) { return; }
            if(item.getAttribute('aria-disabled') == 'true' || item.getAttribute('role') != 'menuitem' ){
                e.stopImmediatePropagation();
                return;
            }

            this.setCurrent(item);
            this.notify();
            this.hideDropdown();
        });

        element.WtwDropdownNavigation = this;
    }


    showDropdown(){
        this.menu.setAttribute('aria-hidden', false);
        this.menu.scrollTo(0, 0);
        this.trigger.setAttribute('aria-expanded', true);

        if(this.animateTrigger){
            this.element.setAttribute('data-active', true);
        }
    }

    hideDropdown(){
        this.menu.setAttribute('aria-hidden', true);
        this.trigger.focus();
        this.trigger.setAttribute('aria-expanded', false);

        // We don't track a current option after the menu has closed
        this.items.forEach(i => {
            i.setAttribute('aria-current', false);
        });
        this.current_item = null;

        if(this.animateTrigger){
            this.element.setAttribute('data-active', false);
        }
    }

    setCurrent(item){
        if(!item) {return;}

        this.items.forEach(i => {
            i.setAttribute('aria-current', false);
        });
        item.setAttribute('aria-current', true);
        this.current_item = item;

        this.scrollToItem(item);

    }

    scrollToItem(item){
        this.menu.scrollTo(0, item.offsetTop);
    }

    // Send a notification event that an item was clicked
    notify(){
        this.element.dispatchEvent(new CustomEvent('wtwdropdownevent', { bubbles: true, detail: { "value" : this.current_item } }));
    }

    isExpanded() {
        return JSON.parse(this.trigger.getAttribute('aria-expanded') || 'false');
    }

    selectPrevious(){
        // If the menu isn't open, open it and quit
        if(!this.isExpanded()){
            this.showDropdown();
            return;
        }

        // Get the index of the current item
        const idx = this.items.indexOf(this.current_item) || 0;

        if(idx == 0){
            return;
        }

        // Get a list of previous items
        const more = this.items.slice(0, idx);

        // Filter out disabled items and select the next one
        const prev = more.filter(item => {return JSON.parse(item.getAttribute('aria-disabled') || 'false') != true;})
        if(prev.length > 0){
            this.setCurrent(prev.at(-1));
        }
        return;
    }

    selectNext(){
        // If the menu isn't open, open it and quit
        if(!this.isExpanded()){
            this.showDropdown();
            return;
        }

        // Get the index of the current item
        const idx = this.items.indexOf(this.current_item) || 0;

        // Get a list of subsequent items
        const more = this.items.slice(idx+1);

        // Filter out disabled items and select the next one
        const next = more.filter(item => {return JSON.parse(item.getAttribute('aria-disabled') || 'false') != true;})
        if(next.length > 0){
            this.setCurrent(next[0]);
        }
        return;
    }
}


// On page load, initialize all tree-filters
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-dropdown-navigation').forEach(element => {
        new WtwDropdownNavigation(element);
    });
});