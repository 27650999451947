'use strict';
class WtwAccordion{
    constructor(element){
        if(!element) {return;}
        /*
            Mode:
            single - a single item can be open at a time
            multiple - multiple items can be opened at the same time
        */
        this.mode = element.dataset.mode || 'single';
        this.items = element.querySelectorAll('.wtw-accordion--item');

        this.items.forEach(item => {
            // Find parts of this item
            item.header = item.querySelector('.wtw-accordion--header');
            item.panel = item.querySelector('.wtw-accordion--panel');

            // Set up cross references
            item.header.panel = item.panel;
            item.header.item = item;
            item.panel.header = item.header;

            // Update the panel's hidden attr to match the header's aria-expanded property
            if(JSON.parse(item.header.getAttribute('aria-expanded') || 'false')){
                item.panel.removeAttribute('hidden');
            }
            else {
                item.panel.setAttribute('hidden', 'hidden');
            }

            // When a header button is clicked
            item.header.addEventListener('click', e => {
                // If the mode is single, then collapse all open items
                if(this.mode == 'single'){
                    this.items.forEach(item => {
                        if(item.header != e.target){
                            this.closeItem(item);
                        }
                    });
                }

                this.toggleItem(e.target);
            });


            // Remove height from panel after animation
            item.panel.addEventListener('transitionend', e => {
                const panel = e.target;
                panel.style.height = null;

                if(JSON.parse(panel.header.getAttribute('aria-expanded') || 'false') == true){
                    return;
                }

                panel.setAttribute('hidden', true);
            });
        });

        element.WtwAccordion = this;
    }

    toggleItem(header){
        const isOpen = JSON.parse(header.getAttribute('aria-expanded') || 'false');
        if(isOpen){
            this.closeItem(header.item);
        }
        else {
            this.openItem(header.item);
        }
    }

    openItem(item){
        item.header.setAttribute('aria-expanded', true);

        // Set height to zero
        item.panel.style.height = `0px`;
        item.panel.removeAttribute('hidden');

        setTimeout(() => {
            this.setHeight(item.panel);
        }, 0);
    }

    closeItem(item){
        item.header.setAttribute('aria-expanded', false);
        // Set the height of the panel so we can transiton back to 0
        this.setHeight(item.panel);

        setTimeout(() => {
            item.panel.style.height = `0px`;
        }, 0);
    }

    setHeight(panel) {
        panel.style.height = `${panel.scrollHeight}px`;
    }
}


// On page load, initialize all accordions
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-accordion').forEach(element => {
        new WtwAccordion(element);
    });
});