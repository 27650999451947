'use strict';
class WtwMotion{

    constructor(){
        console.log('motion');
    }

    static fadeOut(el){
        el.style.opacity = 1;
        (function fade() {
            if ((el.style.opacity -= 0.1) < 0) {
                el.style.display = "none!important";
                if(el.classList.contains('wtw-notification-toast') || el.classList.contains('wtw-notification-inline')){
                    el.close();
                }
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }

    static fadeIn(el, display) {
        el.style.opacity = 0;
        if(el.classList.contains('wtw-notification-toast') || el.classList.contains('wtw-notification-inline')){
            el.show();
        }
        else{
            el.style.display = display || "block";
        }
        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += 0.1) > 1)) {
                el.style.opacity = val;
                requestAnimationFrame(fade);
            }
        })();
    }
}