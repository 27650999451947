'use strict';
class WtwNotificationInline{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        const close = element.querySelector('.wtw-notification-inline--close');

        close.addEventListener('click', e => {
            e.preventDefault();
            this.close();
        });

        element.WtwNotificationInline = this;
    }

    open(){
        WtwMotion.fadeIn(this.element, 'grid');
    }

    close(){
        WtwMotion.fadeOut(this.element);
    }
}


// On page load, initialize all notifications
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-notification-inline').forEach(element => {
        new WtwNotificationInline(element);
    });
});