'use strict';
class WtwSecondaryNavigation{
    constructor(element){

        if(!element) {return;}
        this.element = element;

        this.trigger = element.querySelector(':scope > button');
        if(!this.trigger) {return;}

        this.menu = element.querySelector('ul');
        if(!this.menu) {return;}

        this.items = [...this.menu.querySelectorAll('ul a')];
        this.current_item = this.menu.querySelector('[aria-current="page"]');


        // When the menu has focus, make sure we have a current element
        this.menu.addEventListener('focus', e => {
            if(!this.current_item){
                this.openItem(this.items[0]);
                this.closeMenu(true);
                return;
            }
        });


        this.element.addEventListener('focusout', e => {
            if(e.relatedTarget == null || e.relatedTarget.closest('.wtw-secondary-navigation') == null){
                this.closeMenu(false);
            }

            console.log(e.relatedTarget);
            console.log(e.relatedTarget?.closest('.wtw-secondary-navigation'));
        });


        // When user presses trigger button
        this.trigger.addEventListener('click', e => {
            this.toggleMenu();
        });


        // Watch for keyboard actions
        this.menu.addEventListener('keydown', e => {
            // Arrow Up
            if(e.code == 'ArrowUp'){
                e.preventDefault();
                this.selectPrevious();
                return;
            }

            // Arrow Down
            if(e.code == 'ArrowDown'){
                e.preventDefault();
                this.selectNext();
                return;
            }

            // Escape
            if(e.code == 'Escape'){
                e.preventDefault();
                this.closeMenu(true);
                return;
            }

            // Space bar
            if(e.code == 'Enter' || e.code == 'NumpadEnter' || e.code == 'Space'){
                e.preventDefault();
                this.openItem(this.current_item);
                this.closeMenu(true);
                return;
            }
        });


        // When a user clicks on an item, set it as current
        this.menu.addEventListener('click', e => {
            const item = e.target.closest('a');
            this.openItem(item);
            this.closeMenu(true);
        });

        element.WtwSecondaryNavigation = this;
    }

    isExpanded(){
        return JSON.parse(this.trigger.getAttribute('aria-expanded') || 'false');
    }

    setCurrent(item){
        if(!item) {return;}

        this.current_item = item;
        this.current_item.focus();
    }

    isCurrent(item) {
        return item && item.getAttribute('aria-current') == 'page';
    }

    selectPrevious(){
        if(!this.current_item){
            this.setCurrent(this.items[0]);
            return;
        }

        // Prev location
        const idx = this.items.indexOf(this.current_item) - 1;

        // Get the next element
        if(idx < 0){
            this.setCurrent(this.items[this.items.length - 1]);
            return;
        }

        this.setCurrent(this.items[idx]);
    }

    selectNext(){
        if(!this.current_item){
            this.setCurrent(this.items[0]);
            return;
        }

        // Next location
        const idx = this.items.indexOf(this.current_item) + 1;

        // Get the next element
        if(idx >= this.items.length){
            this.setCurrent(this.items[0]);
            return;
        }

        this.setCurrent(this.items[idx]);
    }

    toggleMenu(){
        if(this.isExpanded()){
            this.closeMenu(true);
            return;
        }
        this.openMenu();
    }

    openMenu(){
        this.trigger.setAttribute('aria-expanded', true);
        this.current_item.focus();
    }

    closeMenu(capture){
        this.trigger.setAttribute('aria-expanded', false);
        if(capture){
            window.setTimeout(e => {
                this.trigger.focus();
            }, 10);
        }
    }

    openItem(item){
        if(this.isCurrent(item)) {return;}

        this.setCurrent(item);

        this.items.forEach(i => {
            i.setAttribute('aria-current', false);
            i.setAttribute('tabindex', '-1');
        });
        item.setAttribute('aria-current', 'page');
        item.setAttribute('tabindex', '0');

        this.notify();
        this.updateTrigger();
    }

    updateTrigger(){
        this.trigger.querySelector('span:first-child').innerHTML = this.current_item.innerHTML;
    }

    // Send a notification event that the value has changed.
    notify(){
        this.menu.dispatchEvent(new CustomEvent('wtwpagechange', { bubbles: true, detail: { "value" : this.current_item } }));
    }
}


// On page load, initialize all tabs
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-secondary-navigation').forEach(element => {
        new WtwSecondaryNavigation(element);
    });
});