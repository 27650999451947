'use strict';
class WtwPagination{
    constructor(element){
        this.element = element;
        this.active = 1;
        this.pages = element.querySelectorAll('.wtw-pagination--pagination li');
        this.numPages = this.pages.length;
        // Groups of pages between ellipsis
        this.groupStart = [];
        this.groupCenter = [];
        this.groupEnd = [];
        // Ellipsis elements between page groups
        this.truncationStart = document.createElement('LI');
        this.truncationStart.innerHTML = `<button disabled="" aria-label="truncated pages"><span>...</span></button>`;
        this.truncationEnd = document.createElement('LI');
        this.truncationEnd.innerHTML = `<button disabled="" aria-label="truncated pages"><span>...</span></button>`;
        // Track elements that need to update
        this.ariaCurrent = element.querySelector('nav[aria-current]');
        this.mobile = element.querySelector('.wtw-pagination--mobile');
        this.jumper = element.querySelector('.wtw-pagination--jumper [type=number]');

        this.truncate();

        // Jumper mode doesn't have page buttons, pull from aria attribute
        if(this.numPages == 0){
            const num = this.jumper.getAttribute('max');
            this.numPages = JSON.parse(num);
        }

        // When user clicks a page button
        this.pages.forEach(page => {
            page.addEventListener('click', e => {
                // Get the page number
                const page = e.target.closest('button').value;
                this.setCurrent(page);
            });
        });

        // When user clicks a previous button
        element.querySelectorAll('button[value=previous]').forEach(button => {
            button.addEventListener('click', e => {
                this.previousPage();
            });
        });

        // When user clicks a next button
        element.querySelectorAll('button[value=next]').forEach(button => {
            button.addEventListener('click', e => {
                this.nextPage();
            });
        });

        // When user edits the jumper text
        element.querySelectorAll('input[type=number]').forEach(input => {
            input.addEventListener('blur', e => {
                // Did the value change?
                let num = e.target.value;
                if(num && JSON.parse(num) != this.active){
                    this.setCurrent(num);
                }
            });

            input.addEventListener('keydown', e => {
                if(e.code == 'Enter' || e.code == 'NumpadEnter'){
                    // Did the value change?
                    let num = e.target.value;
                    if(num && JSON.parse(num) != this.active){
                        this.setCurrent(num);
                    }
                }
            });
        });

        // Allow reference to this instance from element
        element.WtwPagination = this;
    }

    previousPage(){
        this.setCurrent(this.active-1);
    }

    nextPage(){
        this.setCurrent(this.active+1);
    }

    setCurrent(num){
        num = JSON.parse(num);
        num = Math.min(Math.max(num, 1), this.numPages);
        this.active = num;
        this.notify();
        this.ariaCurrent.setAttribute('aria-current', this.active);
        if(this.mobile){
            this.mobile.innerHTML = `Page ${this.active} of ${this.numPages}`;
        }
        if(this.pages.length > 0){
            this.pages.length > 0 && this.truncate();
        }
        if(this.jumper){
            this.jumper.value = this.active;
        }
        this.pages.forEach((page, i) => {
            if((i+1) == this.active){
                page.querySelector('button').setAttribute('aria-current', 'page');
            }
            else{
                page.querySelector('button').removeAttribute('aria-current');
            }
        });
    }

    // Send a notification event that the page has changed.
    notify(){
        this.element.dispatchEvent(new CustomEvent('wtwpagechange', { bubbles: true, detail: { "currentPage" : this.active } }));
    }

    truncate(){
        // Only truncate if needed
        if(this.pages.length < 10){
            return;
        }

        // Figure out active number
        this.groupStart = [];
        this.groupCenter = [];
        this.groupEnd = [];

        this.truncationStart.remove();
        this.truncationEnd.remove();

        // Start group
        if(this.active > 3){
            this.groupStart.push(this.pages[0]);
        }
        if(this.active < 4 || this.active > this.pages.length - 3){
            this.groupStart.push(this.pages[0]);
            this.groupStart.push(this.pages[1]);
            this.groupStart.push(this.pages[2]);
        }
        if(this.active == 3){
            this.groupStart.push(this.pages[3]);
        }

        // Center group
        if(this.active > 3 && this.active < this.pages.length - 2){
            this.groupCenter.push(this.pages[this.active - 2]);
            this.groupCenter.push(this.pages[this.active - 1]);
            this.groupCenter.push(this.pages[this.active]);
        }


        // End group
        if(this.groupCenter.length == 3){
            this.groupEnd.push(this.pages[this.pages.length - 1]);
        }
        else if(this.active == this.pages.length - 3 || this.active == this.pages.length - 2){
            this.groupEnd.push(this.pages[this.pages.length - 4]);
            this.groupEnd.push(this.pages[this.pages.length - 3]);
            this.groupEnd.push(this.pages[this.pages.length - 2]);
            this.groupEnd.push(this.pages[this.pages.length - 1]);
        }
        else if(this.active < 5 || this.active > this.pages.length - 3){
            this.groupEnd.push(this.pages[this.pages.length - 3]);
            this.groupEnd.push(this.pages[this.pages.length - 2]);
            this.groupEnd.push(this.pages[this.pages.length - 1]);
        }


        // Hide all pages
        this.pages.forEach(page => {
            page.classList.add('hidden');
        });

        // Show only the pages in the groups
        this.groupStart.map(page => page.classList.remove('hidden'));
        this.groupStart[this.groupStart.length - 1].insertAdjacentElement('afterend', this.truncationStart);

        this.groupCenter.map(page => page.classList.remove('hidden'));
        if(this.groupCenter.length > 0){
            this.groupCenter[this.groupCenter.length - 1].insertAdjacentElement('afterend', this.truncationEnd);
        }

        this.groupEnd.map(page => page.classList.remove('hidden'));
    }
}


// On page load, initialize all accordions
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-pagination').forEach(element => {
        new WtwPagination(element);
    });
});