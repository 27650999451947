'use strict';
class WtwSearchNavigation{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.textbox = element.querySelector('.wtw-search-navigation--wrapper input');
        this.button = element.querySelector('button');

        // When user clicks to expand search
        this.button.addEventListener('click', e => {
            this.toggleSearch();
        });

        // Watch for window resize
        this.resizeObserver = new ResizeObserver(async (entries) => {
            for (const entry of entries) {
                const islarge = entry.contentRect.width >= 992;

                // Was there a change?
                if(islarge == this.isLarge){return;}

                if(islarge){
                    this.isLarge = true;

                    // Update textbox tabindex
                    this.textbox.setAttribute('tabindex', '-1');
                    this.textbox.setAttribute('aria-hidden', true);
                }
                else {
                    this.isLarge = false;

                    // Update textbox tabindex
                    this.textbox.setAttribute('tabindex', '0');
                    this.textbox.setAttribute('aria-hidden', false);
                }
            }
        });
        this.resizeObserver.observe(document.body);

        element.WtwSearchNavigation = this;
    }



    toggleSearch(){
        if(this.element.classList.contains('expanded')){
            this.collapseSearch();
            return;
        }
        this.expandSearch();
    }

    expandSearch(){
        this.element.classList.add('expanded');
        this.textbox.setAttribute('tabindex', '0');
        this.textbox.focus();
        this.button.setAttribute('aria-expanded', true);
    }

    collapseSearch(){
        this.element.classList.remove('expanded');
        this.textbox.setAttribute('tabindex', '-1');
        this.button.focus();
        this.button.setAttribute('aria-expanded', false);
    }
}


// On page load, initialize all tree-filters
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-search-navigation').forEach(element => {
        new WtwSearchNavigation(element);
    });
});