'use strict';
class WtwFlyout{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.backdrop = element.querySelector('.wtw-flyout--backdrop');
        this.panel = element.querySelector('.wtw-flyout--panel');
        this.closers = element.querySelectorAll('.wtw-flyout--close');

        // When user clicks a close button
        this.closers.forEach(close => {
            close.addEventListener('click', e => {
                this.close();
            });
        });


        // After panel animates, remove
        this.panel.addEventListener('transitionend', e => {
            if(e.target.closest('.wtw-flyout') == null) { return; }
            // if(e.target != this.element) { return; }

            e.stopPropagation();
            // Ignore if we're opening the panel
            if(this.isOpen()){
                this.closers.length > 0 && this.closers[0].focus();
                return;
            }

            // Hide the backdrop and panel
            this.panel.setAttribute('hidden', 'hidden');
            this.panel.setAttribute('aria-hidden', true);

            this.backdrop.setAttribute('hidden', 'hidden');
            this.backdrop.setAttribute('aria-hidden', true);
        });


        element.WtwFlyout = this;
    }

    isOpen(){
        return this.element.classList.contains('wtw-flyout--open');
    }

    open(){
        this.panel.removeAttribute('hidden');
        this.panel.setAttribute('aria-hidden', false);

        this.backdrop.removeAttribute('hidden');
        this.backdrop.setAttribute('aria-hidden', false);

        // Add class to animate
        window.setTimeout(e => {
            this.element.classList.add('wtw-flyout--open');
        }, 0);
    }

    close(){
        this.element.classList.remove('wtw-flyout--open');
        // Hidden attributes are reset with the TransitionEnd event
    }
}


// On page load, initialize all tabs
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-flyout').forEach(element => {
        new WtwFlyout(element);
    });
});