'use strict';
class WtwTooltip{
    constructor(element){
        if(element == null) {return;}

        this.showing = false;
        this.element = element;
        this.trigger = element.querySelector('[data-tooltip]');
        this.tooltip = element.querySelector('.wtw-tooltip--tooltip');
        if(!this.tooltip) {return;}


        this.tooltip.setAttribute('aria-hidden', true);

        // Show the tooltip
        ['mouseenter', 'focus'].forEach(event_name => {
            this.trigger.addEventListener(event_name, e=> {
                this.showTooltip();
            });
        });

        // Hide the tooltip
        ['mouseout', 'focusout'].forEach(event_name => {
            this.trigger.addEventListener(event_name, e=> {
                this.hideTooltip();
            });
        });

        // Pressing escape at any time should close tooltip
        document.addEventListener('keydown', e => {
            if(e.code == 'Escape'){
                this.hideTooltip();
            }
        });

        element.WtwTooltip = this;
    }

    async showTooltip(){
        this.trigger.setAttribute('aria-expanded', true);
        this.trigger.setAttribute('aria-describedby', this.trigger.dataset.tooltip);

        this.tooltip.setAttribute('aria-hidden', false);
        this.showing = true;
    }

    hideTooltip(){
        this.trigger.setAttribute('aria-expanded', false);
        this.trigger.removeAttribute('aria-describedby');

        this.tooltip.setAttribute('aria-hidden', true);
        this.showing = false;
    }
}


// On page load, initialize all tooltips
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-tooltip').forEach(element => {
        new WtwTooltip(element);
    });
});