'use strict';
class WtwTreeFilter{
    constructor(element){
        if(!element) {return;}

        this.menu = element.querySelector('ul');
        if(!this.menu) {return;}

        this.current_item = null;
        this.selected_item = null;
        this.items = Array.from(element.querySelectorAll('[role="treeitem"]'));

        this.menus = this.menu.querySelectorAll('ul');
        this.menus.forEach(menu => {
            // Update the menus's hidden attr to match the menus's aria-expanded property
            const li = menu.closest('li');
            if(this.isExpanded(li)){
                menu.removeAttribute('hidden');
            }
            else {
                menu.setAttribute('hidden', 'hidden');
            }

            // Remove height from sub menu after animation
            menu.addEventListener('transitionend', e => {
                e.stopPropagation();
                const menu = e.target;
                const li = menu.closest('li');
                menu.style.height = null;

                if(this.isExpanded(li)){
                    return;
                }

                menu.setAttribute('hidden', true);
            });
        });

        this.visibleItems = e => {return this.items.filter(i => i.getAttribute('aria-hidden') != 'true')};

        this.search = element.querySelector('.wtw-tree-filter--search input');
        if(this.search){
            this.search.addEventListener('input', e => {
                this.selected_item = null;

                if(e.target.value == ''){
                    this.items.forEach(item => {
                        item.classList.remove('filtered');
                    });
                }
                else{
                    this.filterItems(e.target.value);
                }
            });
        }


        // When the menu has focus, make sure we have a current element
        this.menu.addEventListener('focus', e => {
            if(!this.selected_item){
                // Get the first visible item
                this.setSelected(this.items.filter(i => i.getAttribute('aria-hidden') != 'true')[0]);
                return;
            }
        });


        // Watch for keyboard actions
        this.menu.addEventListener('keydown', e => {

            // Arrow Up
            if(e.code == 'ArrowUp'){
                e.preventDefault();
                this.selectPrevious();
            }

            // Arrow Down
            if(e.code == 'ArrowDown'){
                e.preventDefault();
                this.selectNext();
            }

            // Arrow Right
            if(e.code == 'ArrowRight'){
                e.preventDefault();
                this.openItem(this.selected_item);
            }

            // Arrow Left
            if(e.code == 'ArrowLeft'){
                e.preventDefault();
                this.closeItem(this.selected_item);
            }

            // Space bar
            if(e.code == 'Space' || e.code == 'Enter' || e.code == 'NumpadEnter'){
                e.preventDefault();
                this.setCurrent(this.selected_item);
                this.toggleCheckbox(this.selected_item);
            }
        });


        // When a user clicks on an LI, set it as current
        this.menu.addEventListener('click', e => {
            const item = e.target.closest('li');

            if(e.target.nodeName == 'BUTTON'){
                this.toggleItem(item)
            }

            this.setCurrent(item);
        });


        // Anytime a checkbox is changed, update decendents
        this.menu.addEventListener('input', e => {
            this.updateChildCheckboxes(e.target);
        });


        element.WtwTreeFilter = this;
    }


    filterItems(keyword){
        // Hide all elements
        this.items.forEach(item => this.hideItem(item));

        this.items.forEach(item => {
            const text = item.textContent;

            if(text.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())){
                this.showItem(item);
            }
        });
    }

    hideItem(item){
        item.setAttribute('aria-hidden', true);
        item.classList.add('filtered');
    }

    async showItem(item){
        item.setAttribute('aria-hidden', false);
        item.classList.remove('filtered');

        this.openItem(item);

        const parent = item.parentElement.closest('li');
        parent && this.showItem(parent);
    }

    toggleCheckbox(item){
        if(!item){ return; }
        const checkbox = item.querySelector(':scope > div [type=checkbox]');
        if(checkbox){
            checkbox.checked = !checkbox.checked;
        }
        this.updateChildCheckboxes(checkbox);
    }

    updateChildCheckboxes(checkbox){
        if(!checkbox) { return; }
        const checked = checkbox.checked;

        checkbox.closest('li').querySelectorAll('[type=checkbox]').forEach(c => {
            c.checked = checked;
        });
    }

    setSelected(item){
        if(!item) {return;}

        this.items.forEach(i => {
            i.setAttribute('aria-selected', false);
        });
        item.setAttribute('aria-selected', true);
        this.selected_item = item;
    }

    setCurrent(item){
        if(!item) {return;}

        this.items.forEach(i => {
            i.setAttribute('aria-current', false);
            i.setAttribute('aria-selected', false);
        });
        item.setAttribute('aria-current', true);
        this.current_item = item;
        this.selected_item = item;
    }

    isExpanded(item) {
        return item && JSON.parse(item.getAttribute('aria-expanded') || 'false');
    }

    selectPrevious(){
        if(!this.selected_item){
            this.setSelected(this.items[0]);
            return;
        }

        let previous = this.selected_item.previousElementSibling;
        if(!previous){
            // Get the parent element
            const parent = this.selected_item.parentElement.closest('li');
            if(parent){
                this.setSelected(parent);
                return;
            }

            return;
        }


        // If previous doesn't have children, set it as current
        const idx = this.items.indexOf(this.selected_item) - 1;
        const previous_children = this.items.filter((x,i) => {return i <= idx && x.parentElement.getAttribute('aria-hidden') != 'true' && !x.parentElement.closest('[aria-expanded="false"]')});
        if(!this.isExpanded(previous) || previous_children.length == 0){
            this.setSelected(previous);
            return;
        }


        // Get the furthest visible item
        this.setSelected(previous_children.reverse()[0]);
        return;
    }

    selectNext(){
        if(!this.selected_item){
            this.setSelected(this.items[0]);
            return;
        }

        const submenu = this.selected_item.querySelector('ul');

        // If the current item has children and is expanded, get first child
        if(submenu && this.isExpanded(this.selected_item)){
            const idx = Array.from(this.items).indexOf(this.selected_item) + 1;
            if(idx < this.items.length){
                this.setSelected(this.items[idx]);
            }
            return;
        }

        // Otherwise get the next sibling
        if(this.selected_item.nextElementSibling){
            this.setSelected(this.selected_item.nextElementSibling);
            return;
        }

        // Keep going up looking for an LI with a sibling
        let parent = this.selected_item.closest('ul').closest('li');
        while(parent && !parent.nextElementSibling){
            parent = parent.closest('ul').closest('li');
        }
        if(!parent) {return;}

        if(parent.nextElementSibling){
            this.setSelected(parent.nextElementSibling);
            return;
        }

        return;
    }

    toggleItem(item){
        if(!item){ return; }

        if(this.isExpanded(item)){
            this.closeItem(item);
        }
        else {
            this.openItem(item);
        }
    }

    async openItem(item){
        // If this doesn't have a submenu, don't expand
        if(![...item.childNodes].find(n => n.nodeName == 'UL')){return;}
        if(this.isExpanded(item)) {return;}

        item.setAttribute('aria-expanded', true);
        const submenu = item.querySelector('ul');

        // Set height to zero
        submenu.style.height = `0px`;
        submenu.removeAttribute('hidden');
        submenu.setAttribute('aria-hidden', 'false');

        setTimeout(() => {
            this.setHeight(submenu);
        }, 0);
    }

    closeItem(item){
        // If this doesn't have a submenu, don't collapse
        if(![...item.childNodes].find(n => n.nodeName == 'UL')){return;}
        if(!this.isExpanded(item)) {return;}

        item.setAttribute('aria-expanded', false);
        const submenu = item.querySelector('ul');
        submenu.setAttribute('aria-hidden', 'true');

        // Set the height of the panel so we can transiton back to 0
        this.setHeight(submenu);

        setTimeout(() => {
            submenu.style.height = `0px`;
        }, 0);
    }

    setHeight(menu) {
        menu.style.height = `${menu.scrollHeight}px`;
    }
}


// On page load, initialize all tree-filters
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-tree-filter').forEach(element => {
        new WtwTreeFilter(element);
    });
});