'use strict';
class WtwInputFile{
    constructor(element){
        if(!element) {return;}
        this.element = element;

        this.input = element.querySelector('[type=file]');
        if(!this.input){ return; }

        this.title = element.querySelector('.wtw-input-file--status-title');
        this.description = element.querySelector('.wtw-input-file--status-description');
        this.cancel = element.querySelector('.wtw-input-file--status-cancel');
        this.pause = element.querySelector('.wtw-input-file--status-pause');
        this.retry = element.querySelector('.wtw-input-file--retry');
        this.status = element.querySelector('.wtw-input-file--status');
        this.instructions = element.querySelector('.wtw-input-file--wrapper');
        this.link = element.querySelector('.wtw-input-file--link');


        // When the upload link is clicked
        this.link.addEventListener('click', e => {
            e.preventDefault();
            this.input.click();
        });


        // When a file is selected
        this.input.addEventListener('input', e => {

            // File details
            let filename = '';
            let filesize = '';

            // Get the selected file(s)
            const files = this.input.files;
            if(files.length == 0) { return; }

            filename = files[0].name;
            filesize = files[0].size;

            // Update UI
            this.title.textContent = filename;
            this.description.textContent = `${Math.ceil(filesize/1024)} KB`;

            // Switch to different mode
            this.reset();
            this.element.classList.add('wtw-input-file--selected');
            this.status.setAttribute('aria-hidden', false);
            this.instructions.setAttribute('aria-hidden', true);
        });


        // When user clicks to remove file
        this.cancel.addEventListener('click', e => {
            this.input.value = null;
            this.reset();
        });


        // When user clicks to retry
        this.retry.addEventListener('click', e => {
            this.reset();
        });


        // When user clicks to pause upload
        this.pause.addEventListener('click', e => {
            this.pauseUpload();
        });


        // When user drags a file over
        this.input.addEventListener('dragover', async e => {
            if(this.element.classList.contains('dragover')){
                return;
            }
            this.element.classList.add('dragover');
        });

        // When user stops dragging
        this.input.addEventListener('dragend', async e => {
            this.element.classList.remove('dragover');
        });


        element.WtwInputFile = this;
    }

    reset(){
        this.element.classList.remove('dragover');
        this.element.classList.remove('wtw-error');
        this.element.classList.remove('wtw-input-file--selected');
        this.input.value = null;
        this.status.setAttribute('aria-hidden', true);
        this.instructions.setAttribute('aria-hidden', false);
    }

    pauseUpload(){
    }

    displayError(){
        this.reset();
        this.element.classList.add('wtw-error');
    }
}


// On page load, initialize all file
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-input-file').forEach(element => {
        new WtwInputFile(element);
    });
});