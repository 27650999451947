'use strict';
class WtwStepper{
    constructor(element){
        if(element == null) {return;}

        this.input = element.querySelector('input');

        element.addEventListener('click', e => {
            // Which button was clicked?
            if(e.target.dataset.amount == 'less'){
                return this.decrement();
            }
            if(e.target.dataset.amount == 'more'){
                return this.increment();
            }
        });

        element.WtwStepper = this;
    }

    increment(){
        let val = JSON.parse(this.input.value || '0');
        let step = JSON.parse(this.input.step || '1');
        let max = JSON.parse(this.input.max || '0');
        this.input.value = Math.min(val + step, max);
    }

    decrement(){
        let val = JSON.parse(this.input.value || '0');
        let step = JSON.parse(this.input.step || '1');
        let min = JSON.parse(this.input.min || '0');
        this.input.value = Math.max(val - step, min);
    }
}


// On page load, initialize all tooltips
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.wtw-stepper').forEach(element => {
        new WtwStepper(element);
    });
});